import {
  useGetPreferredLanguage,
  useGetSupportedLanguages,
  useSetPreferredLanguage,
} from "../apis/preferredLanguage";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import checkCircleImg from "../images/check-circle.svg";
import { LoadSpinner } from "./LoadSpinner";
import "../styles/PreferredLanguage.css";
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { appInsights } from "../AppInsights";

export const PreferredLanguage = () => {
  const { mutate: setPreferredLanguage, isSuccess } = useSetPreferredLanguage();
  const { data: preferredLanguage, isLoading: isPreferredLanguageLoading } =
    useGetPreferredLanguage();
  const { data: supportedLanguages, isLoading: isSupportedLanguageLoading } =
    useGetSupportedLanguages();

  const isLoading = isPreferredLanguageLoading || isSupportedLanguageLoading;

  const [language, setLanguage] = useState("");

  const Languages = () => {
    if (
      supportedLanguages &&
      supportedLanguages.length !== 0 &&
      preferredLanguage
    ) {
      return (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Language</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            variant="outlined"
            value={preferredLanguage.language}
            onChange={(e) => {
              appInsights.trackEvent({
                name: "ChangeLanguge",
                iKey: e.target.value,
              });
              setPreferredLanguage(e.target.value);
              setLanguage(e.target.value);
            }}
            label="Language"
            sx={{
              width: "210px",
              backgroundColor: "white",
            }}
          >
            {supportedLanguages.map((language: any) => (
              <MenuItem key={language.name} value={language.name.trim()}>
                {language.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else {
      return null;
    }
  };

  const CheckCircle = () => {
    if (isSuccess) {
      return (
        <div>
          <img
            src={checkCircleImg}
            style={{
              height: "25px",
              width: "25px",
              marginRight: "6px",
            }}
          />
          <p className="d-inline-block">Saved</p>
        </div>
      );
    } else {
      return null;
    }
  };

  if (isLoading) {
    return <LoadSpinner />;
  }

  const NBText = () => {
    if (
      preferredLanguage !== undefined &&
      preferredLanguage.language !== "ENG" &&
      preferredLanguage.language !== "ENU"
    ) {
      return (
        <div>
          <p>
            <b>
              <u>
                NB! Some documents are not available in this language but will
                be shown in English.
              </u>
            </b>
          </p>
        </div>
      );
    }
  };

  return (
    <div className="ml-auto">
      <Typography variant="h5" align="center" style={{ marginTop: "30px" }}>
        Preferred Language
      </Typography>
      <Typography
        variant="subtitle1"
        align="center"
        style={{ marginTop: "5px", marginBottom: "70px", color: "#555" }}
      >
        Select preferred document language
      </Typography>
      <form>
        <Row>
          <Col></Col>
          <Col xs={3}>
            <div className="d-inline-block" style={{ verticalAlign: "middle" }}>
              <Languages />
            </div>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <div
            className="d-inline-block"
            style={{ verticalAlign: "middle", marginTop: "15px" }}
          >
            <CheckCircle />
          </div>
        </Row>
        <Row>
          <Col></Col>
          <Col xs={6}>
            <br />
            {NBText()}
          </Col>
          <Col></Col>
        </Row>
      </form>
    </div>
  );
};
