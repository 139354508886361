import { GetDocumentsByDMSNumberDocument } from "../apis/lookup";

export function groupBy(xs: any, key: string) {
  return xs.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

const compareVestasTurbines = (first: string, second: string): number => {
  let regex = /V(\d+)/; // capture '174' in e.g. turbine name 'V174 9.5/10.0MW Offshore'
  let firstCapture = first.match(regex)?.[1];
  let secondCapture = second.match(regex)?.[1];

  if (firstCapture === undefined || secondCapture === undefined) return 0;

  if (firstCapture === secondCapture) return first.localeCompare(second);

  let firstInt = parseInt(firstCapture);
  let secondInt = parseInt(secondCapture);

  return firstInt < secondInt ? -1 : 1;
};

const compareNonVestasTurbines = (first: string, second: string): number => {
  return first.localeCompare(second);
};

export const sortTurbines = (turbineNames: string[]): string[] => {
  let vestasTurbines = turbineNames.filter((turbineName) =>
    /V\d+/.test(turbineName)
  );
  let nonVestasTurbines = turbineNames.filter(
    (turbineName) => vestasTurbines.indexOf(turbineName) < 0
  );

  vestasTurbines.sort(compareVestasTurbines).reverse();
  nonVestasTurbines.sort(compareNonVestasTurbines);

  return [...vestasTurbines, ...nonVestasTurbines];
};

export const isVideoFile = (fileExtension: string): boolean => {
  fileExtension = fileExtension.startsWith(".")
    ? fileExtension
    : `.${fileExtension}`;

  return [".mp4"].includes(fileExtension.toLowerCase());
};

export const isExcelFile = (fileExtension: string): boolean => {
  fileExtension = fileExtension.startsWith(".")
    ? fileExtension
    : `.${fileExtension}`;

  return [".xls", ".xlsx", ".xlsm"].includes(fileExtension.toLowerCase());
};

type DocumentToBlobFilename = Pick<
  GetDocumentsByDMSNumberDocument,
  "documentName" | "docGUID" | "documentFormat"
>;

export const generateTDocBlobFilename = (
  document: DocumentToBlobFilename
): string => {
  const fileExtension = document.documentFormat.startsWith(".")
    ? document.documentFormat
    : `.${document.documentFormat}`;

  return `${
    document.documentName
  }_${document.docGUID.toUpperCase()}${fileExtension}`;
};
