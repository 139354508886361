import { useState, useEffect, useMemo } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/esm/Col";
import "../styles/Lookup.scss";
import { useNavigate } from "react-router";
import { LoadSpinner } from "./LoadSpinner";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { SearchType, useSearch } from "../apis/lookup";
import { Chip } from "@mui/material";
import { useGetPreferredLanguage } from "../apis/preferredLanguage";
import { generateTDocBlobFilename, isVideoFile } from "../helpers/utility";

interface StateError {
  mode: "search" | "error";
  message?: string;
}

interface Mode {
  mode: "DMS" | "Material";
}

export const Lookup = () => {
  const navigate = useNavigate();
  const [state, setState] = useState<Mode>({ mode: "DMS" });
  const [text, setText] = useState("");
  const [searchText, setSearchText] = useState<string | null>(null);
  const [error, setError] = useState<StateError>({
    mode: "search",
    message: "",
  });
  const { data: preferredLanguage } = useGetPreferredLanguage();

  useEffect(() => {
    const searchMode = getSearchMode();
    if (searchMode == null) {
      setSearchMode({ mode: "DMS" });
      setError({ mode: "search" });
    } else {
      setState(searchMode);
    }
  }, []);

  const setSearchMode = (mode: Mode) => {
    window.localStorage.setItem("mode", JSON.stringify(mode));
    setState(mode);
    setError({ mode: "search" });
  };

  const getSearchMode = (): Mode | null => {
    const mode = window.localStorage.getItem("mode");
    return mode ? JSON.parse(mode) : null;
  };

  const { data, isLoading } = useSearch(
    searchText,
    getSearchMode()?.mode === "DMS" ? SearchType.DMS : SearchType.Material
  );

  const goToViewer = useMemo(() => {
    if (data == null) return;

    const filterPreferredLanguage = preferredLanguage?.language ?? "ENG";

    let preferredDocuments = data.filter(
      (document) => document.language === filterPreferredLanguage
    );

    // Fallback if no documents with preferred language filter is found
    if (preferredDocuments.length === 0) {
      preferredDocuments = data.filter(
        (document) => document.language === "ENG"
      );
    }

    if (preferredDocuments.length === 0 && searchText && !isLoading) {
      setError({
        mode: "error",
        message:
          state.mode === "DMS"
            ? "The number does not match any document. Please try a different one. Remember - or / if that is part of the number"
            : "The number does not match any material. Please try a different one.",
      });

      return;
    }

    if (preferredDocuments.length === 1 && isVideoFile(preferredDocuments[0].documentFormat)) {
      const videoDocument = preferredDocuments[0];
      const videoFilename = generateTDocBlobFilename(videoDocument);
      navigate(`/videoPlayerTdoc/${videoFilename}`, { state: {} });
      return;
    }

    const pageToNavigate =
      preferredDocuments.length > 1 ? "/result" : "/pdfviewer";

    navigate(pageToNavigate, { state: { documents: preferredDocuments } });
  }, [data, navigate, searchText, state.mode, isLoading]);

  const SearchModeButtons = () => {
    const searchMode = getSearchMode();
    if (searchMode == null) {
      return null;
    }

    const isDmsSelected = state.mode === "DMS";
    const isMateralSelected = state.mode === "Material";

    return (
      <div>
        <div className="d-inline-block button">
          <ModeButton
            label="Document number"
            selected={isDmsSelected}
            handleOnClick={() => {
              setSearchMode({ mode: "DMS" });
              setSearchText(null);
            }}
          />
          <ModeButton
            label="Material number"
            selected={isMateralSelected}
            handleOnClick={() => {
              setSearchMode({ mode: "Material" });
              setSearchText(null);
            }}
          />
        </div>
      </div>
    );
  };

  const SearchButton = () => {
    const searchMode = getSearchMode();
    if (searchMode == null) {
      return null;
    }

    return (
      <div className="Searchbutton">
        <Button
          className="button"
          variant="contained"
          sx={{
            backgroundColor: "#00B5E4",
            borderColor: "#00B5E4",
            borderRadius: "4px",
          }}
          onClick={() => {
            setSearchText(text);
          }}
        >
          {state.mode === "DMS" ? "Retrieve document" : "Retrieve documents"}
        </Button>
      </div>
    );
  };

  const InfoLabel = () => {
    if (error.mode === "search") {
      const searchMode = getSearchMode();

      if (searchMode == null) return null;
      state.mode = searchMode.mode;

      const labelText =
        state.mode === "DMS" ? (
          <>Write down the exact document number</>
        ) : (
          <>
            Write down the exact material number <br /> Document lookup based on
            material number is only available for Transport documentation
          </>
        );

      return (
        <div className="InfoLabel">
          <Form.Label style={{ color: "#555" }}>{labelText}</Form.Label>
        </div>
      );
    } else if (error.mode === "error") {
      return (
        <div className="Searchbox">
          <Form.Label style={{ color: "red" }}>{error.message}</Form.Label>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="lookup-container center">
      <Typography
        variant="h5"
        align="center"
        style={{ marginTop: "30px", marginBottom: "80px" }}
      >
        Lookup
      </Typography>
      <Stack justifyContent="center" direction="column">
        <Col md={{ span: 6, offset: 3 }}>
          <Stack className="Searchbox" gap={2.2}>
            <SearchModeButtons />
            <TextField
              fullWidth
              type="searchQuery"
              label={
                state.mode === "DMS" ? "Document number" : "Material number"
              }
              placeholder={
                state.mode === "DMS"
                  ? "e.g: /xxxxxx or xxxx-xxxx"
                  : "e.g: xxxxxx"
              }
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </Stack>
        </Col>
        <Col md={{ span: 6, offset: 3 }}>
          <Stack sx={{ "margin-top": "0.75rem" }}>
            <InfoLabel />
          </Stack>
        </Col>
      </Stack>
      <Stack justifyContent="center" alignItems="center">
        <SearchButton />
        {goToViewer}
      </Stack>
      {isLoading && <LoadSpinner />}
    </div>
  );
};

const ModeButton = ({
  label,
  selected,
  handleOnClick,
}: {
  label: string;
  selected: boolean;
  handleOnClick: () => any;
}) => {
  return (
    <Chip
      label={label}
      variant="outlined"
      className="Search-Mode"
      sx={{
        backgroundColor: selected ? "#00B5E499" : "white",
        width: "140px",
        textTransform: "none",
        borderColor: selected ? "#00B6E4" : "none",
        "margin-right": "10px",
        color: selected ? "white" : "black",
      }}
      onClick={handleOnClick}
    />
  );
};
