import { Link } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import "../styles/Safety.css";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import caretImg from "../images/caret-right.svg";
import "../styles/docList.scss";
import { Typography } from "@mui/material";
import { useSafetyDocuments } from "../apis/useSafetyDocs";
import { LoadSpinner } from "./LoadSpinner";
import { useEffect } from "react";
import { groupBy } from "../helpers/utility";

export const SafetyPage = () => {
  const { isLoading, data } = useSafetyDocuments();

  const Documents = () => {
    if (data != null) {
      return (
        <div>
          <Typography
            variant="h5"
            align="center"
            style={{ marginTop: "30px", marginBottom: "60px" }}
          >
            Safety
          </Typography>
          <ListGroup className="docsList">
            {data
              .filter(
                (document: { language: string }) => document.language === "ENG"
              )
              .map((document: any) => (
                <Row key={document.documentName}>
                  <Col></Col>
                  <Col xs={8}>
                    <Link
                      to="/pdfviewer"
                      state={{
                        documents: [document.documentData],
                        mode: "Special",
                      }}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListGroup.Item action>
                        {document.documentName}
                        <span className="float-right">
                          <img
                            src={caretImg}
                            style={{ verticalAlign: "middle" }}
                          />
                        </span>
                      </ListGroup.Item>
                    </Link>
                  </Col>
                  <Col></Col>
                </Row>
              ))}
          </ListGroup>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <div className="App">
      <Documents />
      {isLoading && <LoadSpinner />}
    </div>
  );
};
