import { useState, useRef, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router-dom";
import { useGetPreferredLanguage } from "../apis/preferredLanguage";
import "../styles/pdfviewer.scss";
import { MakeFavorite } from "./MakeFavorite";
import { ShowPDF } from "./ShowPDF";
import { useAddToHistory } from "../apis/useHistory";
import WebViewer from "@pdftron/pdfjs-express-viewer";
import { GetDocumentsByDMSNumberDocument } from "../apis/lookup";

interface LocationState {
  documents: GetDocumentsByDMSNumberDocument[];
  mode?: "Special";
}

export const PDFViewer = () => {
  const { instance, accounts } = useMsal();
  const location = useLocation();
  const locationState = location.state as LocationState;
  const mode = locationState.mode;
  const document = locationState.documents[0];
  const { mutate: mutateHistory } = useAddToHistory(instance, accounts);
  const [PdfInstance, setPdfInstance] = useState<any | null>(null);
  const [Language, setLanguage] = useState("");
  const [pdfLoaded, setPdfLoaded] = useState(false);
  const viewer = useRef<HTMLDivElement | null>(null);
  const libPath = window.origin + "/webviewer/lib";
  const { data: preferredLanguage } = useGetPreferredLanguage();

  useEffect(() => {
    setPdfLoaded(false);
    console.log("pdf loaded: " + pdfLoaded);
    if (mode !== "Special" && document !== undefined) {
      mutateHistory(document.dmsNumber);
      setLanguage(document.language);
    }

    WebViewer(
      {
        licenseKey: process.env.REACT_APP_PDF_LICENSE,
        path: libPath,
        disabledElements: ["filePickerButton", "downloadButton", "printButton"],
        extension: "pdf",
      },
      viewer.current!!
    )
      .then((instance: any) => {
        setPdfInstance(instance);
      })
      .catch((e: any) => console.log(e));
  }, [libPath, preferredLanguage]);

  const initPDFViewer = () => {
    console.log("pre " + pdfLoaded);

    if (PdfInstance == null || pdfLoaded) return;

    console.log("after " + pdfLoaded);
    if (mode === "Special") {
      setPdfLoaded(true);
      PdfInstance.UI.loadDocument(document);
    } else {
      let documentUrl = new URL(document.documentData!);
      documentUrl.searchParams.append("platform", "WEB");
      setPdfLoaded(true);
      PdfInstance.UI.loadDocument(documentUrl.toString());
    }
  };

  const Buttons = () => {
    if (Language === "") return;

    return (
      <div>
        <div className="float-right btn-bar">
          <div className="d-inline-block" style={{ width: "15vw" }}>
            <ShowPDF
              pdfInstance={PdfInstance}
              language={Language}
              data={[document]}
              mode={mode}
            />
          </div>
          <div
            className="d-inline-block"
            style={{ verticalAlign: "bottom", marginLeft: "3px" }}
          >
            {mode !== "Special" && (
              <MakeFavorite
                pdfInstance={PdfInstance}
                documentName={document.documentName}
                DMSNumber={document.dmsNumber}
                mode={mode ?? ""}
                documentUrl={document.documentData!}
              />
            )}
          </div>
        </div>
        <br />
        <br />
      </div>
    );
  };

  return (
    <>
      {initPDFViewer()}
      {Buttons()}
      <div
        className="webviewer"
        ref={viewer}
        style={{ height: "90vh", marginTop: "25px" }}
      />
    </>
  );
};
